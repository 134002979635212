#External Libraries
import InfiniteLoading from 'vue-infinite-loading'
import Vue from 'vue/dist/vue.esm'
import TurbolinksAdapter from 'vue-turbolinks'
import VueAutonumeric from 'vue-autonumeric'
import UniqBy from 'lodash.uniqby'
import UiKit from 'uikit';

# Local imports
import fetchItems from '../methods/items/fetch-item'
import toCurrency from '../filters/to_currency'
import AxiosRequest from "../utils/axios-request"

Vue.use(TurbolinksAdapter)
document.addEventListener 'turbolinks:load', () ->
  el = document.getElementById 'show-cause'
  return false unless el?

  data = ->
    { searchParams } = new URL(window.location.href)
    # Default values
    page = 1
    discounts = window.location.pathname == '/descuentos'
    sort =
      updated_at: 'desc'
    id = parseInt document.getElementById('id').dataset.id
    # Negotiation
    page |= parseInt searchParams.get('page')
    keywords = searchParams.get('keyword')
    discounts |= parseInt searchParams.get('discounts')

    {
      items: []
      services: []
      filter_interactions: 1
      page
      loading: true
      search_filters: {
        keywords
        discounts
      }
      id
      sort
    }
  new Vue {
    el
    data
    mounted: ->
      @fetchItems()
    methods: {
      fetchItems
      Favourite: (item, uid) ->
        AxiosRequest('/customers/favourites', {item_id: item.id, customer_id: uid}, 'post').then ({data}) ->
          UiKit.modal(document.getElementById("card-#{item.id}-modal")).hide();
          Swal.fire({
            title: data.message
            icon: 'success'
            toast: true
            position: 'top-end'
            timer: 2000
          }).then ->
            console.log 'success'
            item.favourite = true
      DeleteFavourite: (item, uid) ->
        Swal.fire(
          title: '¿Estás seguro?'
          text: ''
          icon: 'warning'
          showCancelButton: true
          confirmButtonColor: '#30bbe5'
          cancelButtonColor: '#d33'
          cancelButtonText: 'Cancelar'
          confirmButtonText: 'Sí, quitar favorito').then (result) =>
            if result.value
              UiKit.modal(document.getElementById("card-#{item.id}-modal")).hide();
              AxiosRequest("/customers/favourites/remove", {item_id: item.id, customer_id: uid}, 'post').then ({data}) =>
                item.favourite = false
                Swal.fire({
                  title: data.message
                  icon: 'success'
                  toast: true
                  position: 'top-end'
                  timer: 2000
                })
            else if result.dismiss == Swal.DismissReason.cancel
              Swal.fire({
                title: 'Cancelado'
                icon: 'error'
                toast: true
                position: 'top-end'
                timer: 2000
              }).then ->
                console.log 'Cancelado'
      filtersChanged: ->
        window.scrollTo(0, 0)
        @page = 1
        @fetchItems()
      assignProperty: (property, value) ->
        @search_filters[property] = value
      clearProperty: (property, reload = true) ->
        @search_filters[property] = '';
        @fetchItems() if reload
      infiniteHandler: ($state) ->
        @fetchItems('infinite', $state)
      resetState: ->
        window.location.reload()
    }
    watch: {
      'search_filters.keywords': -> @filtersChanged()
      'search_filters.discounts': -> @filtersChanged()
    }
    components: {
      InfiniteLoading
      VueAutonumeric
    }
    computed: {
      active_price_filters: -> @search_filters.discounts
      active_filters: ->
        f = @search_filters
        f.keywords || f.discounts
    }
    filters: {
      toCurrency
    }
  }
