import {get} from 'axios'
import {debounce} from 'throttle-debounce'
import UniqBy from 'lodash.uniqby'

export default debounce(1000, false, (mode = 'get', $state) ->
  @loading = true
  {
    discounts
    keywords
  } = @search_filters

  q = {
    items_with_discount: discounts if discounts
    title_i_cont_all: keywords if keywords
  }

  request = get("/causas/#{@id}/search_cause.json", { params: { q, @page, sort: [@sort] } })
  page = 0

  if mode == 'infinite'
    request.then ({data}) =>
      @loading = false
      quantity = data.items.length
      new_items = @items
      new_items.push(data.items...)
      @items = UniqBy(new_items, 'id')
      if (quantity < 15)
        $state.complete()
        @page = 0
      else
        @page++
        $state.loaded()
  if mode == 'get'
    request.then ({data}) =>
      @page++
      @items = data.items
      @loading = false
)
